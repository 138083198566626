module.exports = {
  button_upcoming: "次回",
  button_history: "過去",
  button_all: "すべて",
  title_confirmation_no: "予約番号",
  title_booking_date: "予約日時",
  title_arrival: "到着",
  title_departure: "出発",
  title_hotel_name: "ホテル",
  title_status: "予約状況",
  explore_hotel: "ホテルを探検する",

  reservation_emptynote: "今すぐ予約してポイントを獲得しましょう",
  reservation_footnote:
    "予約情報が更新されるまで、最大2日ほどかかる場合があります。",
};
