module.exports = {
  title_processed_date: "処理日",
  title_description: "説明",
  title_place_of_visit: "訪問場所",
  title_transaction_reference: "交換内容の参照",
  title_transaction_type: "交換タイプ",
  title_earned_point: "獲得ポイント",
  title_bonus_point: "ボーナスポイント",
  title_total_point: "合計ポイント",
  title_total_expiry: "ポイント期限",
  explore_hotel: "ホテルを探検する",
  points_emptynote: "今すぐ予約してポイントを獲得しましょう",
};
