module.exports = {
  title_redemption_date: "交換日",
  title_voucher_code: "バウチャーコード",
  title_category: "カテゴリー",
  title_voucher_name: "バウチャー名",
  title_point_redeemed: "ポイント交換",
  title_voucher_expiry: "バウチャー期限",
  title_status: "ステータス",
  button_title: "報酬を請求する",
  description: "ポイントを使用してバウチャー特典を利用してください。",
};
