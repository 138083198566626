module.exports = {
  title_welcome: "ようこそ, ",
  title_second_welcome: "様",
  information:
    "あなたにふさわしい贅沢なAYANA Rewards会員特典を体験してください。ご宿泊ごとに、無料宿泊、特別なアップグレード、会員限定のオファー、特別なウェルカムアメニティをお楽しみいただけます。",
  member_name_label: "メンバー名",
  learn_more: "詳しく見る",
  expires_on: "有効期限:",
  earn_points: "ポイントを獲得する",
  claim_rewards: "報酬を請求する",
  rewards_points: "リワードポイント",
  nights: "泊",
  level_points: "レベルポイント",
  upgrade_level: "メンバーシップレベルのアップグレードについて学ぶ",
  maintain_level: "あなたのレベルを維持する方法を学ぶ",
  maintain_memebrship: "メンバーシップレベルを維持する",
  upgrade_membership: "メンバーシップレベルのアップグレード",
  membership_no: "メンバーシップナンバー.",
  label_membership: "メンバーシップ ",
  membership_gold_label: "Gold",
  membership_silver_label: "Silver",
  membership_platinum_label: "Platinum",
  point_label: "あなたのポイント",
  menu_tab_profile: "個人情報",
  menu_tab_my_redeem: "ポイントの交換",
  menu_tab_password_change: "パスワードを変更する",
  menu_tab_missing_point: "不足ポイント",
  menu_tab_reward_history: "リワード履歴",
  menu_tab_future_booking: "先々のご予約",
  menu_tab_feedback: "フィードバック",
  menu_tab_my_voucher: "バウチャー",
  menu_tab_transaction_history: "ポイント履歴",
  menu_tab_my_reservation: "予約詳細",
  label_your_point: "お客様のポイント",
  label_expired_on: "有効期限",
  label_required_nex_point: "まで %pts% ポイント",
  claim_rewards_popup_drawer_title: "報酬を請求する",
  claim_rewards_popup_drawer_description:
    "参加ブランドでの宿泊料金または独占体験の支払いにポイントを使用できます。",
  claim_rewards_popup_drawer_explore: "下記の報酬引き換えを探索してください",
  claim_rewards_popup_drawer_pay_with_points: "ポイントで支払う",
  claim_rewards_popup_drawer_pay_with_points_description:
    "ホテル予約に使用するポイント数を選択し、取引の柔軟性を提供します。",
  claim_rewards_popup_drawer_voucher: "報酬バウチャー",
  claim_rewards_popup_drawer_voucher_description:
    "ホテル、ダイニング、スパなど、さまざまなバウチャーとポイントを交換できます。",
  rewards_points_title: "AYANA Rewardsポイントについて",
  rewards_points_terms_conditions: "AYANA Rewardsの利用規約を読む",
  rewards_points_description:
    "対象となる宿泊料金、ダイニング、スパトリートメント、参加ブランドのレクリエーション施設を利用することでポイントを獲得できます。",
  rewards_points_expiration_detail:
    "ポイントは、最終活動（ログイン、ポイント取得、ポイント引換）の日から2年間有効です。",
  your_benefits: "あなたの特典",
  exclusive_room_discount: "限定ルーム割引",
  exclusive_discount_participating_brands: "参与ブランドの独占割引",
  birthday_gift_during_stay: "宿泊期間中の誕生日プレゼント*",
  wellness_benefits: "健康特典*",
  tier_room_upgrade: "1級客室アップグレード*",
  early_check_in: "11 AMからの早めのチェックイン*",
  late_check_out_4pm: "午後4時までの遅延チェックアウト*",
  bonus_points_25: "25%ボーナスポイント",
  welcome_gifts: "ウェルカムギフト**",
  complimentary_one_time_breakfast: "無料のワンタイム朝食",
  bonus_points_50: "50%ボーナスポイント",
  late_check_out_6pm: "午後6時までの遅延チェックアウト*",
  based_on_availability: "在庫状況に応じて",
  varies_by_property: "施設によって異なります",
  learn_more_about_member_benefit: "メンバー特典について詳しく知る",
  maintaining_your_level: "あなたのレベルの維持",
  earn_more_level_points: "さらにレベルポイントを獲得する",
  terms_conditions: "利用規約",
  or: "か",
};
